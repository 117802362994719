import { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { useSourceDetails } from './source-details';

amplitude
    .getInstance()
    .init("6f37859a574eccbabbf46e83a20e1b19");
export const useAmplitudeLog = (arg1) => {
    const pathName = useLocation().pathname;
    const mobileSearchParams = useLocation().search;
    const source = useSourceDetails();

    const logEventByName = (eventName,options) => {
        amplitude
        .getInstance()
        .logEvent(eventName, options?Object.assign(options,{source}):{source});
    }
    const logSearchResults = (data) => {
        let type = [], name = [];
        if (data == null) {
            const obj = new URLSearchParams(mobileSearchParams);
            type = Array.from(obj.keys()).join(",");
            name = Array.from(obj.values()).join(",");
        } else if (data !== null) {
            data && data.length > 0 && data.forEach(item => {
                if (item.results) {
                    item.results.forEach(subitem => {
                        type.push(subitem.entity_type.raw)
                        name.push(subitem.entity_id.raw);
                    })
                }
            })
        }
        amplitude
            .getInstance()
            .logEvent("searchresults", { source, type, name })
    }
    const logPage = (page) => {
        amplitude
            .getInstance()
            .logEvent(page, { source })
    }
    useEffect(() => {
        if (arg1==="app" && pathName === "/") {
            logPage("homepage")
        } else if (arg1==="app" && (pathName === '/info'|| pathName === '/getInformation' || pathName === '/details')) {
            if (mobileSearchParams !== "" && (pathName === '/getInformation' || pathName === '/details')) {
                logSearchResults();
            }
        }else if(pathName==='/about'){
            logEventByName("About Page")
        }
    }, [pathName]);

    return { logSearchResults,logEventByName };

}