import React, { useEffect } from 'react';

const RedirectLink = () => {
  useEffect(() => {
    // Redirect to example.com when component mounts
    window.location.href = 'https://www.amexglobalbusinesstravel.com/';
  }, []); // Empty dependency array ensures the effect runs only once after mounting

  // Placeholder content while redirecting
  return null;
};

export default RedirectLink;
