import React, {useState, useEffect} from 'react';
import { NavLink, withRouter } from 'react-router-dom';

const CurrentPageIndicator = ({location}) => {
    let initaisPagesState = {
        search: {isActive: location.pathname.substr(1) === ""},
        info: {isActive: ["info", "details","getInformation"].indexOf(location.pathname.substr(1))>-1},
        about: {isActive: location.pathname.substr(1) === "about"},
    }
    let [pagesState, setPagesState] = useState(initaisPagesState);
    let [currentActivePage, setCurrentActivePage] = useState(
           location.pathname.substr(1) === "" ? "search" : 
            location.pathname.substr(1) === "details" ? "info" : 
            location.pathname.substr(1) === "getInformation" ? "info" : location.pathname.substr(1));

    const clickHandler = (id) => {
        if(currentActivePage !== id) {
            setPagesState({...pagesState, ...pagesState[id].isActive = true, ...pagesState[currentActivePage].isActive = false});
            setCurrentActivePage(id);
        }
    }

    useEffect(() => {
        let id = location.pathname.substr(1) === "" ? "search" : 
        location.pathname.substr(1) === "details" ? "info" : 
        location.pathname.substr(1) === "getInformation" ? "info" : location.pathname.substr(1)
        if(id !== currentActivePage)
            setPagesState({...pagesState, ...pagesState[id].isActive = true, ...pagesState[currentActivePage].isActive = false});
        setCurrentActivePage(location.pathname.substr(1) === "" ? "search" : 
        location.pathname.substr(1) === "details" ? "info" : 
        location.pathname.substr(1)=== "getInformation" ? "info" : location.pathname.substr(1));
    }, [location.pathname])

    return (
        <div className={"container"}>
            {
                Object.keys(pagesState).map((page, index) => {
                    const indicatorBoxStyles = {
                        background: pagesState[page].isActive ? "transparent": "",
                        border: pagesState[page].isActive ? "1px solid dodgerblue": ""
                    }
                    const indicatorStyles = {
                        background: pagesState[page].isActive ? "dodgerblue": ""
                    }
                    return (
                        <div className={"indicator__item"} key={page}>
                            <div>
                                <NavLink data-testid={`search-one-lnk-${index}`} to={page === "search" ? "/" : `/${page}`} className={"indicator__box"} style={indicatorBoxStyles} onClick={() => clickHandler(page)}>
                                    <span className={"indicator"} style={indicatorStyles}></span>
                                </NavLink>
                                <NavLink 
                                    data-testid={`search-lnk-${index}`}
                                    to={page === "search" ? "/" : `/${page}`} 
                                    onClick={() => clickHandler(page)} 
                                    className={"label link white-text"} 
                                    style={{textTransform: "uppercase"}}
                                >
                                    {page}
                                </NavLink>
                            </div>
                            {index !== Object.keys(pagesState).length-1 && <span className={"seperator"}></span>}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default withRouter(CurrentPageIndicator);